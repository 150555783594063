<template>
  <div class="w-100">
    <div
      class="title-container w-100 d-flex flex-wrap align-items-center justify-content-between px-3"
    >
      <h1 class="mb-0 welcome">{{ title ? title : "Welcome" }}</h1>
      <DateFilter :is-qfzp="false" @custom-change="handleCustomChange" />
    </div>
    <div class="w-100">
      <div
        class="w-100 collapse-container flex-wrap d-flex justify-content-between align-items-center"
      >
        <h4 class="title w-50 mb-0">TRN No.- {{ taxNo }}</h4>
        <h4 class="title w-50 mb-0">Jurisdiction- {{ jurisdictionNo }}</h4>
        <!-- <div
          class="d-flex flex-1 flex-wrap justify-content-end align-items-center"
        >
          <select
            name=""
            v-model="current_business"
            class="date-select pl-2 mr-3"
            hidden
            disabled
          >
            <option v-if="business.data?.length > 1" value="master" selected>
              Group Data
            </option>
            <option
              v-for="(item, index) in business.data"
              :key="item.id"
              class="option"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div> -->
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import DateFilter from "./DateFilter.vue";
import { storeToRefs } from "pinia";
import { useProfitAndLossStore } from "../../../store/ProfitAdLoss";
import { watch, computed } from "vue";
import { useRoute } from "vue-router";
import { useEntityStore } from "../../../store/entity";
import { useBusinessStore } from "../../../store/buisness.store";
export default {
  name: "TaxTitleAndDate",
  components: { DateFilter },
  props: {
    title: String,
  },
  setup() {
    const businessStore = useBusinessStore();
    const profitAndLossStore = useProfitAndLossStore();
    const route = useRoute();
    const entityStore = useEntityStore();
    const { entity } = storeToRefs(entityStore);

    const { business, current_business } = storeToRefs(businessStore);
    const { financial_year, financial_period } =
      storeToRefs(profitAndLossStore);

    const taxNo = computed(() => {
      if (!entity?.value.data) {
        return "";
      }
      return entity?.value.data.tax[0]?.corp_tax_trn_details;
    });
    const jurisdictionNo = computed(() => {
      if (!entity?.value.data) {
        return "";
      }
      return entity?.value.data?.license[0]?.license_authority?.name;
    });

    watch(
      business,
      () => {
        const business_id = route.query.business
          ? route.query.business
          : business.value?.data
          ? business.value?.data?.length > 1
            ? "master"
            : business.value?.data[0]?.id
          : "";
        current_business.value = business_id ? business_id : "";
        // tab.value = business_id;
      },
      { deep: true }
    );

    return {
      business,
      profitAndLossStore,
      businessStore,
      financial_year,
      financial_period,
      current_business,
      taxNo,
      jurisdictionNo,
    };
  },
  data() {
    return {
      gbusiness: [],
    };
  },
  mounted() {
    //this.getBusiness()
  },
  methods: {
    handleCustomChange(s) {
      this.gbusiness = s;
    },
  },
};
</script>

<style scoped>
.title-container {
  min-height: 74px;
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.welcome {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.055em;
  color: #111827;
}
.date-filter {
  max-width: 163px;
  height: 40px;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 50px;
}
.options {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #000000;
}
.collapse-container {
  min-height: 54px;
  border-top: 1px solid rgba(116, 113, 113, 0.5);
  border-bottom: 1px solid rgba(116, 113, 113, 0.5);
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  letter-spacing: 0.055em;
  color: #000000;
}
.date-select {
  cursor: pointer;
  background: #deeded;
  border: 1px solid rgba(149, 146, 146, 0.3);
  border-radius: 4px;
  width: 150px;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.date-select:focus {
  outline: 0;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
@media screen and (max-width: 856px) {
  .date-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .welcome {
    margin-top: 10px;
  }
}
</style>
