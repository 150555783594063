<template>
  <div class="row g-0">
    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="wrapper w-100">
        <h1 class="welcome">Welcome</h1>
        <p class="mb-0 desc">Your Tax Compliance in a Snap!!</p>
        <p class="mb-0 footer-desc">© {{ getYear() }}All rights reserved.</p>
      </div>
    </div>
    <div id="account-main" class="col-lg-6 col-md-12 col-sm-12 cont">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInSignUpContainer",
  async mounted() {
    if (window.innerWidth < 992) {
      this.scrollIntoView();
      window.addEventListener("resize", this.scrollIntoView);
    }
    if (this.$route.query.r) {
      await this.$router.push(`${location.pathname}`);
      location.reload(true);
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.scrollIntoView);
  },
  methods: {
    getYear() {
      const date = new Date();
      return date.getFullYear();
    },
    scrollIntoView() {
      const main = document.getElementById("account-main");
      setTimeout(() => {
        main.scrollIntoView({ behavior: "smooth" });
      }, 2000);
    },
  },
};
</script>

<style scoped>
.wrapper {
  background: linear-gradient(153.14deg, #101432 1.59%, #00a7a7 100%);
  display: flex;
  flex-direction: column;
  min-height: 125vh;
  align-items: center;
  justify-content: center;
}
.welcome {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
  letter-spacing: 0.055em;
  color: #b0e1eb;
}
.logo {
  width: 201px;
  height: 210px;
  margin-top: 78px;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.055em;
  color: #b0e1eb;
}
.footer-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #b0e1eb;
  position: absolute;
  bottom: 39px;
  left: 38px;
}
.triangle {
  top: 50vh;
  left: 60vh;
  background: #d9d9d9;
  transform: rotate(90deg);
  width: 84px;
  height: 63.75px;
}
.cont {
  background: #fafbff;
}
</style>
