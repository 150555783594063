<template>
  <div id="mySidenav" class="sidenav">
    <div
      class="d-flex w-100 h-100 flex-column align-items-center justify-content-between"
    >
      <div
        class="main w-100 d-flex flex-column align-items-center justify-content-center"
      >
        <div
          v-if="width < 600"
          class="close-icon-container"
          @click="setToggle(false)"
        >
          <img src="@/assets/svg/chevron-left.svg" class="close-icon" alt="" />
        </div>
        <div class="side-nav-container d-flex flex-column">
          <router-link
            v-for="navitem in navigation"
            :key="navitem.id"
            :to="navitem.link"
            :class="`side-nav-item ${
              navitem.link.includes(routerName)
                ? routerName === '/'
                  ? navitem.link === '/'
                    ? 'active'
                    : ''
                  : 'active'
                : ''
            } d-flex align-items-center`"
          >
            <img :src="navitem.icon ? navitem.icon : UserIcon" alt="" />
            <p class="mb-0 side-nav-text">
              {{ navitem.name }}
            </p>
          </router-link>
        </div>
      </div>
      <div class="w-100">
        <div class="upgrade-container">
          <p class="mb-0 upgrade-desc">
            Upgrade to PRO to get access all features!
          </p>
          <button
            v-if="profile.data.has_subscription === 'N'"
            class="subscribe-button"
            @click="$router.push(`/subscription`)"
          >
            Subscribe Now!
          </button>
          <button
            v-if="profile.data.has_subscription === 'Y'"
            class="subscribe-button"
            @click="$router.push(`/subscription`)"
          >
            Upgrade Now!
          </button>
        </div>
        <p class="mb-0 copyright">© {{ getYear() }} All rights reserved.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch, onUnmounted } from "vue";
import UserIcon from "@/assets/svg/user_light.svg";

import { useRoute } from "vue-router";
import { useProfileStore } from "../../store/profile";
import { storeToRefs } from "pinia";
import {
  getFinancialRoute,
  mainNavigation,
  subUseNavigation,
} from "../../constants/navigation";
const props = defineProps({
  sideNavType: {
    type: String,
    default: "",
  },
  setToggle: {
    type: Function,
    default: () => {},
  },
});

const route = useRoute();
const profileStore = useProfileStore();
const { profile } = storeToRefs(profileStore);
const width = ref(window.innerWidth);
const id = route?.params?.id;
const navigation = ref([]);
const getYear = () => {
  const date = new Date();
  return date.getFullYear();
};
const financialRoutes =
  "/" + route.path.split("/")[1] + "/" + route.path.split("/")[2];
const normalRoutes = "/" + route.path.split("/")[1];
const routerName = ref(
  props?.sideNavType === "financialData" ? financialRoutes : normalRoutes
);
onMounted(() => {
  navigation.value =
    props?.sideNavType === "financialData"
      ? getFinancialRoute(id, route.query)
      : !Number(profile.value.data?.parent_id)
      ? mainNavigation
      : subUseNavigation;
  window.addEventListener("resize", () => {
    width.value = window.innerWidth;
  });
});
const unWatchProfileData = watch(
  () => profile.value?.data,
  (newValue) => {
    navigation.value =
      props?.sideNavType === "financialData"
        ? getFinancialRoute(id, route.query)
        : !Number(newValue?.parent_id)
        ? mainNavigation
        : subUseNavigation;
  },
  { deep: true }
);
const unWatchRouteQuery = watch(
  () => route.query,
  (newValue) => {
    navigation.value =
      props?.sideNavType === "financialData"
        ? getFinancialRoute(id, newValue)
        : !Number(profile.value.data?.parent_id)
        ? mainNavigation
        : subUseNavigation;
  },
  { deep: true }
);
onUnmounted(() => {
  unWatchProfileData();
  unWatchRouteQuery();
  window.removeEventListener("resize", async () => {
    width.value = window.innerWidth;
  });
});
</script>

<style scoped>
.sidenav {
  height: 125vh;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  background: linear-gradient(153.14deg, #101432 1.59%, #00a7a7 100%);
  overflow-x: hidden;
  transition: 0.5s;
  padding: 42px 25px 26px 25px;
  width: 321px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.sidenav::-webkit-scrollbar {
  display: none;
}
.main {
  gap: 30px;
}
.dashboard-button {
  cursor: pointer;
  width: 252px;
  height: 55px;
  padding-left: 18px;
  gap: 19px;
  background: #0f75bc;
  border-radius: 4px;
  color: #fafbff;
}
.side-nav-container {
  gap: 42px;
  text-decoration: none;
}
.side-nav-item {
  gap: 18px;
  padding-left: 18px;
  padding-right: 18px;
}

.side-nav-item:hover {
  background: #0f75bc;
  border-radius: 4px;
  color: #fafbff;
  height: 55px;
}
.side-nav-item.active {
  background: #0f75bc;
  border-radius: 4px;
  color: #fafbff;
  height: 55px;
}
.side-nav-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.055em;
  color: #b0e1eb;
}
.upgrade-container {
  border: 2px solid #fafbff;
  border-radius: 12px;
  padding: 23px 30px 18px 30px;
}
.upgrade-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #ffffff;
}
.subscribe-button {
  width: 217px;
  height: 37px;
  background: #00a7a7;
  border-radius: 18.5px;
  margin-top: 17px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #ffffff;
}
.copyright {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.055em;
  color: #fafbff;
  margin-top: 21px;
}
.close-icon-container {
  position: absolute;
  top: 22px;
  right: 2px;
  background-color: #fff;
  cursor: pointer;
}
</style>
